<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        indicators
        background=""
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <img
          v-if="$vuetify.breakpoint.lgAndUp"
          src="@/assets/images/anlas-header.png"
          width="920"
          height="120"
          class="top-image"
        />

        <img
          v-if="$vuetify.breakpoint.mdAndDown"
          src="@/assets/images/logos/anlas-logo.png"
          width="100%"
          height="100%"
          alt="logo"
          class="grid-center"
        />

        <div v-else>
          <b-carousel-slide
            v-for="(item, index) in randomizedSlides"
            :key="index"
            :img-src="item.imgSrc"
            class=""
          ></b-carousel-slide>
        </div>
      </b-carousel>

      <div class="transparent">
        <div xl="3" lg="4" md="5" class="center bg" :style="!isDark ? 'background-color:#efeef0' : null">
          <!-- login form -->
          <v-card-text class="custom-card-text">
            <v-card-title class="px-0"> Şifre Unuttum </v-card-title>
            <validation-observer ref="observer">
              <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Eski Şifre" rules="required">
                <v-text-field
                  v-model="username"
                  filled
                  :background-color="isDark ? '#373351' : 'white'"
                  autocomplete="new-password"
                  :error-messages="errors"
                  class="mb-6"
                  label="Kullanıcı Adınız"
                  hide-details
                ></v-text-field>
              </validation-provider>
            </validation-observer>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forget link -->

              <router-link
                :to="{ name: 'auth-login' }"
                class="ms-3 font-weight-medium ls-n1"
                :class="isDark ? 'white--text' : 'primary--text'"
              >
                Giriş Yap
              </router-link>

              <v-btn
                tile
                color="#000000"
                type="submit"
                class="text-capitalize text-body-2 white--text"
                x-large
                :loading="loading"
                @click="submit"
              >
                Şifre Sıfırla
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { postDataPublic } from '@utils'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  data() {
    return {
      slide: 0,
      slides: [
        { imgSrc: require('@/assets/images/sliders/capraR.jpeg') },
        { imgSrc: require('@/assets/images/sliders/capraRD.jpeg') },
        { imgSrc: require('@/assets/images/sliders/capraX.jpeg') },
        { imgSrc: require('@/assets/images/sliders/capraXRally.jpeg') },
        { imgSrc: require('@/assets/images/sliders/vientoSport.jpeg') },
        { imgSrc: require('@/assets/images/sliders/wintergripPlus.jpeg') },

        // Diğer resimler...
      ],
    }
  },
  computed: {
    randomizedSlides() {
      // Resimleri rastgele sıralama
      return this.shuffleArray(this.slides)
    },
  },

  methods: {
    shuffleArray(array) {
      // Fisher-Yates algoritmasını kullanarak dizi elemanlarını rastgele sıralama
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }

      return array
    },
    onSlideStart(slide) {
      // Slide geçiş başlangıcında yapılacak işlemler
    },
    onSlideEnd(slide) {
      // Slide geçiş sonunda yapılacak işlemler
    },
  },

  setup() {
    const isPasswordVisible = ref(false)
    const username = ref(null)
    const loading = ref(false)
    const observer = ref(null)
    const { isDark } = useAppConfig()
    const submit = async () => {
      const isValid = await observer.value.validate()
      if (isValid) {
        loading.value = true
        postDataPublic({
          method: 'memberPassReset',
          username: username.value,
        }).then(response => {
          if (response.error === 1) {
            observer.value.reset()
            username.value = null
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'warning',
              background: '#FF4C51',
              confirmButtonColor: '#000',
            })
          } else if (response.error === false) {
            observer.value.reset()
            username.value = null
            loading.value = false
            Vue.swal({
              title: 'Başarılı',
              text: response.msg,
              icon: 'success',
              background: '#56CA00',
              confirmButtonColor: '#000',
            })
          }
        })
      }
    }

    return {
      isPasswordVisible,
      username,
      isDark,
      submit,
      loading,
      observer,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  max-width: 100%; /* Added for responsiveness */
  width: 30%; /* Default width for larger screens */
  left: 30%;
}

.top-image {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 3920px;
  max-height: 110px;
  height: auto;
}
.custom-card-text {
  width: 100%;
}

@media (max-width: 1200px) {
  /* Styles for screens up to 1200px wide */
  .center {
    width: 400; /* Adjusted width for smaller screens */
  }
}

@media (max-width: 992px) {
  /* Styles for screens up to 992px wide */
  .center {
    width: 400; /* Further adjusted width for even smaller screens */
  }
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .center {
    width: 400px; /* Adjusted width for mobile screens */
    left: 50%; /* Resets the left position to center horizontally */
    transform: translateX(-50%); /* Removes vertical translation */
  }
}

.transparent {
  opacity: 0.9;
}
.grid-center {
  display: grid;
  place-items: center;
  padding-top: 50%;
}
</style>
